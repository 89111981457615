import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./components/MainPage/MainApp/App";
import Error from "./components/Layout/Error";
import Ad from "./components/IndividualAd/Ad";
import Stepper from "./components/AdForm/Stepper";
import Header from "./components/Layout/Header/Header";
// import Footer from "./components/Layout/Footer";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { AppContextProvider, useGlobalContext } from "./context";
import Register from "./components/Accounts/Register";
import LoginPage from "./components/Accounts/LoginPage";
import Activate from "./components/Accounts/Activate";
import Dashboard from "./components/Dashboard/DashboardTabs/Dashboard";
// import EnquiryForm from "./components/IndividualAd/EnquiryForm";
// import MyEnquiry from "./components/Dashboard/MyEnquiry";
// import Chat from "./components/Dashboard/Chat";
import Management from "./components/Management/Management";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import UserProfile from "./components/Accounts/UserProfile/UserProfile";
import PasswordReset from "./components/Accounts/PasswordReset";
import PasswordResetConfirm from "./components/Accounts/PasswordResetConfirm";
import OwnerAds from "./components/IndividualAd/OwnerAds/OwnerAds";
import { HelmetProvider } from "react-helmet-async";
import AdActivationPage from "./components/AdForm/AdActivationPage";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import TermsOfUse from "./components/Policies/TermsOfUse";
import AboutUs from "./components/Policies/AboutUs";
import { Typography } from "@mui/material";

const theme = createTheme();

// Redirection script to ensure all traffic to kurnamai.lt is redirected to www.kurnamai.lt
(function () {
  if (window.location.hostname === "kurnamai.lt") {
    window.location.href = window.location.href.replace(
      "kurnamai.lt",
      "www.kurnamai.lt"
    );
  }
})();

// prevent orientation change on mobile devices
if (window.orientation !== undefined) {
  window.addEventListener("orientationchange", function () {
    if (window.orientation !== 0) {
      window.orientation = 0;
    }
  });
}

// palette: {
//   success: {
//     main: "#2e7d32",
//   },
// },

//   {
//   palette: {
//     primary: {
//       main: "#1976d2",
//     },
//   },
// }

// const useStyles = makeStyles((theme) => {
//   root: {
//     // some css that access to theme
//   }
// });

function ProtectedRoute({ children, ...rest }) {
  const { userState } = useGlobalContext();
  // console.log("rest", rest);
  // alert("protected route");
  return (
    <Route
      {...rest}
      render={({ location }) => {
        // if (!userState.authenticationAttempted || userState.userLoggingIn) {
        //   alert("user logging in");
        //   return <Typography sx={{ mt: 20 }}>Kraunama...</Typography>; // or your loading spinner
        // } else
        if (!!userState.isAuthenticated) {
          // alert("user authenticated");
          return children;
        } else {
          // alert("user not authenticated");
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: location.pathname } }}
            />
          );
        }
      }}
    />
  );
}

// double check if HelmetProvider is still needed

const routing = (
  <HelmetProvider>
    {" "}
    <ThemeProvider theme={theme}>
      <Router>
        {/* <React.StrictMode> */}
        <AppContextProvider>
          {console.log("mui Theme", theme)}
          {/* <Box
            sx={{
              height: "100vh",
              border: "solid red",
              display: "flex",
              flexDirection: "column",
            }}
          > */}
          <Header />
          <Switch>
            <ProtectedRoute exact path="/create-ad">
              <Stepper />
            </ProtectedRoute>
            <ProtectedRoute exact path="/payment-processed">
              <AdActivationPage />
            </ProtectedRoute>
            {/* <ProtectedRoute path="/dashboard/:tab?"> */}
            <ProtectedRoute path="/dashboard/:tabName?/:adId?/:enquirerId?/:extension?">
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute path="/management">
              <Management />
            </ProtectedRoute>
            <ProtectedRoute path="/user-profile">
              <UserProfile />
            </ProtectedRoute>
            <Route exact path="/register" component={Register} />
            <Route exact path="/activate/:uid/:token" component={Activate} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/password-reset" component={PasswordReset} />
            <Route
              exact
              path="/password/reset/confirm/:uid/:token"
              component={PasswordResetConfirm}
            />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/:adTypeLabel?" component={App} />
            {/* <Route exact path="/:search" component={App} /> */}
            <Route
              exact
              path="/ad/:adId/:enquirerId?/:extension?"
              component={Ad}
            />
            <Route exact path="/ad/:id/enquiry" component={Ad} />
            <Route exact path="/user/:ownerId/:name" component={OwnerAds} />
            <Route path="*" component={Error} />
          </Switch>
          {/* <ProtectedRoute exact path="/ad/:id/enquiry">
          <EnquiryForm />
        </ProtectedRoute> */}
          {/* <Footer /> */}
          {/* </Box> */}
        </AppContextProvider>
        {/* </React.StrictMode> */}
      </Router>
    </ThemeProvider>
  </HelmetProvider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(routing);

// ReactDOM.render(routing, document.getElementById("root"));

// listener to navigate to search results
navigator.serviceWorker.addEventListener("message", function (event) {
  if (event.data.action === "navigate") {
    console.log("received navigate message");
    window.location.href = event.data.url;
  }
});
